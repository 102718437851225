import React from 'react';
import PropTypes from 'prop-types';
import Template from 'templates';
import { GetServerSideProps } from 'next';
import { generateProductsPage } from 'lib/wordpress/pages';

const ProductPage = ({ pageData }) => <Template data={pageData} template="Modules" />;

ProductPage.defaultProps = {
    pageData: {},
};

ProductPage.propTypes = {
    pageData: PropTypes.oneOfType([PropTypes.object]),
};

export default ProductPage;

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
    const data = await generateProductsPage(query);
    return {
        notFound: data?.notFound,
        props: {
            layout: { ...data?.layout },
            pageData: { ...data?.pageData },
            title: data?.title,
        },
    };
};
